<template>
  <div>
    <slot name="before"></slot>
    <va-popover
      v-if="hasAction('index') && currentUser.can(permissionController, 'index')"
      :message="$t('tables.actions.index')"
      placement="up"
    >
      <va-button
        flat
        small
        color="primary"
        icon="fa fa-list-ul"
        :to="getRoute('Index')"
      />
    </va-popover>
    <va-popover
      v-if="hasAction('new') && currentUser.can(permissionController, 'add')"
      :message="$t('tables.actions.new')"
      placement="up"
    >
      <va-button
        flat
        small
        color="primary"
        icon="fa fa-plus"
        :to="getRoute('New')"
      />
    </va-popover>
    <va-popover
      v-if="hasAction('view') && currentUser.can(permissionController, 'view')"
      :message="$t('tables.actions.view')"
      placement="up"
    >
      <va-button
        flat
        small
        color="primary"
        icon="fa fa-search-plus"
        :to="getRoute('View', actionData)"
      />
    </va-popover>
    <va-popover
      v-if="hasAction('edit') && currentUser.can(permissionController, 'edit')"
      :message="$t('tables.actions.edit')"
      placement="up"
    >
      <va-button
        flat
        small
        color="secondary"
        icon="fa fa-edit"
        :to="getRoute('Edit', actionData)"
      />
    </va-popover>
    <va-popover
      v-if="hasAction('delete') && currentUser.can(permissionController, 'delete')"
      :message="$t('tables.actions.delete')"
      placement="up"
    >
      <va-button
        flat
        small
        color="danger"
        icon="fa fa-trash"
        @click.prevent="onAction('delete-item', actionData)"
      />
    </va-popover>
    <slot></slot>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'action-bar',
  props: {
    actionData: {
      type: Object,
      default: () => { },
    },
    crudLinks: {
      type: String,
      default: '',
    },
    controller: {
      type: String,
      default: '',
    },
    deleteRoute: {
      type: String,
      default: null,
    },
    actions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    permissionController () {
      let controller = this.controller
      if (controller.length === 0) {
        controller = this.crudLinks
      }
      return this.normalize(controller)
    },
    action () {
      return this.$route.meta.action
    },
    itemId () {
      return this.$route.params ? this.$route.params.id : null
    },
  },
  methods: {
    hasAction (action) {
      return this.actions.some(a => a === action)
    },
    onAction (action, params) {
      switch (action) {
        case 'delete-item':
          this.tryDelete(params)
          break
      }
    },
    getRoute (action, props) {
      if (!props) {
        return { name: this.crudLinks + action }
      }

      let params = null
      const keys = Object.keys(props)
      params = {}
      for (const k of keys) {
        params[k] = props[k]
      }
      return { name: this.crudLinks + action, params }
    },
    async tryDelete (props) {
      let id = null
      if (!props || !props.id) {
        this.showToast(this.$t('notifications.system.failure'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        return
      }
      id = props.id

      let controller = this.deleteRoute || this.controller
      if (controller === '') {
        controller = this.crudLinks
      }

      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        confirmButtonText: this.$t('layout.buttons.confirm'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          let u = null
          try {
            u = await this.$http.delete(controller + '/' + id)
          } catch (error) {
            this.$swal.showValidationMessage(`Request failed: ${error}`)
            return
          }

          return u.data
        },
      })

      if (result.value) {
        const routeController = this.crudLinks || this.controller
        this.$router.push({ name: routeController + 'Index' })
      }
    },
    capitalize (s) {
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    normalize (word) {
      let full = ''
      word.split('-').forEach(w => {
        full += this.capitalize(w)
      })
      return full
    },
  },
}
</script>

<style lang="scss" scoped>
.action-button {
  padding: 10px;
  color: inherit;
}
</style>
